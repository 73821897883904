import * as constants from '../consts/index'

import { Hero } from '../components/components/hero/TwoColumnWithVideoForContact'
import Layout from '../components/layout'
import React from 'react'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import styled from 'styled-components/macro'
import tw from 'twin.macro'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'

const HighlightedText = tw.span`bg-green-500 text-gray-100 px-4 py-4 inline-block`
const SectionHeading = tw.h2`mt-4 text-4xl sm:text-5xl font-bold tracking-tight text-center`
const SectionContainer = tw.div`text-gray-700 relative bg-white`
const MapContainer = tw.div`lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden p-10 flex items-end justify-start relative md:h-auto h-screen w-full`
const MapTextContainer = tw.div`bg-white relative flex flex-wrap py-6 rounded shadow invisible md:visible`
const MapTextAddressContainer = tw.div`lg:w-1/2 px-6`
const MapTextLabel = tw.h2` font-medium text-gray-900 tracking-widest text-sm`
const MapTextCompanyName = tw.h1` font-bold text-gray-800 tracking-tight`

const MapTextContactContainer = styled(MapTextAddressContainer)`
  ${tw`mt-4 lg:mt-0`}
`

const MapTextContactNumber = styled(MapTextLabel)`
  ${tw`mt-4`}
`

// const FormContainer = tw.form`w-full md:w-1/2 lg:w-1/3 xl:w-1/4 bg-white px-6 flex flex-col py-8 mt-8 md:mt-0`
const FormTitle = tw.h2`text-green-500 text-lg mb-1 font-medium`
const FormSubtitle = tw.p`leading-relaxed mb-5 text-gray-600 text-2xl font-bold`
const FormControl = tw.div`relative mb-4`
const FormLabel = tw.label`leading-7 text-sm text-gray-600`
// const FormTextInput = tw.input`w-full bg-white rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out`
// const FormTextArea = tw.textarea`w-full bg-white rounded border border-gray-300 focus:border-indigo-500 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out`
const FormButton = tw.button`text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 text-lg mt-4`
const FormNoteText = tw.p`text-xs text-gray-500 mt-3`

function ContactPage({ data }) {
  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&')
  }

  const [recaptchaValue, setRecaptchaValue] = React.useState('')
  const handleChangeReCAPTCHA = React.useCallback((value) => {
    setRecaptchaValue(value)
  }, [])
  return (
    <Layout
      bgColor={constants.bgColor}
      isDark={constants.isDark}
      Hero={
        <Hero
          bgColor={constants.bgColor}
          heading={
            <>
              Get in Touch <br />
              <span className={'text-green-500'}>with us</span>
            </>
          }
          description={
            'Our team is happy to answer any of your enquiries. Fill out the\n' +
            "            form below and we'll be in touch as soon as possible.\n"
          }
          isDark={constants.isDark}
          imageSrc={data.file.childImageSharp.fluid}
          imageCss={'rounded-2xl shadow-xl z-10'}
          imageDecoratorBlob={false}
        />
      }
    >
      <SEO
        keywords={[
          `about us`,
          `about`,
          `spice`,
          `spice supplier`,
          `sinaran rempah`,
          `spices`,
          `spices malaysia`,
        ]}
        title="Contact"
      />
      <SectionContainer>
        <SectionHeading>
          Our <HighlightedText>Location</HighlightedText>
        </SectionHeading>
        <div className="container px-5 py-24 mx-auto flex sm:flex-no-wrap flex-wrap">
          <MapContainer>
            <iframe
              className="absolute inset-0"
              title="map"
              marginHeight="0"
              marginWidth="0"
              scrolling="no"
              src="https://maps.google.com/maps?width=100%&height=100%&
                            hl=en&q=SINARAN+REMPAH+ASLI+SDN.+BHD.+NO.+311,+Jln+Industri+Galla+
                            15,+GALLA+INDUSTRI+PARK,+70200+Seremban,+Negeri+Sembilan
                            &ie=UTF8&t=&z=16&iwloc=B&output=embed"
              width="100%"
              height="100%"
              frameBorder="0"
            />
            <MapTextContainer>
              <MapTextAddressContainer>
                <MapTextLabel>ADDRESS</MapTextLabel>
                <MapTextCompanyName>
                  Sinaran Rempah Asli Sdn. Bhd.
                </MapTextCompanyName>
                <p className="leading-relaxed">
                  NO. 311, Jln Industri Galla 15, GALLA INDUSTRI PARK, 70200
                  Seremban, Negeri Sembilan
                </p>
              </MapTextAddressContainer>
              <MapTextContactContainer>
                <MapTextLabel>EMAIL</MapTextLabel>
                <a className="text-indigo-500 leading-relaxed">
                  sinaranrempahasli8888@gmail.com
                </a>
                <MapTextContactNumber>PHONE</MapTextContactNumber>
                <p className="leading-relaxed">{'016-7783867'}</p>
              </MapTextContactContainer>
            </MapTextContainer>
          </MapContainer>
          <div className="mt-8 mb-4 block md:hidden">
            <div className="bg-white relative flex flex-wrap py-6 rounded shadow">
              <MapTextAddressContainer>
                <MapTextLabel>ADDRESS</MapTextLabel>
                <MapTextCompanyName>
                  Sinaran Rempah Asli Sdn. Bhd.
                </MapTextCompanyName>
                <p className="leading-relaxed">
                  NO. 311, Jln Industri Galla 15, GALLA INDUSTRI PARK, 70200
                  Seremban, Negeri Sembilan
                </p>
              </MapTextAddressContainer>
              <MapTextContactContainer>
                <MapTextLabel>EMAIL</MapTextLabel>
                <a className="text-indigo-500 leading-relaxed break-all">
                  sinaranrempahasli8888@gmail.com
                </a>
                <MapTextContactNumber>PHONE</MapTextContactNumber>
                <p className="leading-relaxed">{'016-7783867'}</p>
              </MapTextContactContainer>
            </div>
          </div>
          <Formik
            initialValues={{
              name: '',
              email: '',
              contactNumber: '',
              message: '',
              recaptcha: '',
            }}
            onSubmit={(values, actions) => {
              if (recaptchaValue != null && recaptchaValue !== '') {
                fetch('/?no-cache=1', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                  body: encode({
                    'form-name': 'contact-us',
                    'g-recaptcha-response': encodeURIComponent(recaptchaValue),
                    ...values,
                  }),
                })
                  .then(() => {
                    alert('Success')
                    actions.resetForm()
                  })
                  .catch(() => {
                    alert('Error')
                  })
                  .finally(() => actions.setSubmitting(false))
              }
            }}
            validate={(values) => {
              const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
              const contactNumberRegex = /^[+]?(6)?(01)[0-46-9]*[-]?[0-9]{7,8}$/g
              const errors = {}
              if (!values.name) {
                errors.name = 'Name Required'
              }
              if (!values.email || !emailRegex.test(values.email)) {
                errors.email = 'Valid Email Required'
              }
              if (!values.message) {
                errors.message = 'Message Required'
              }

              if (!values.recaptcha) {
                errors.recaptcha = 'Please complete the recaptcha'
              }

              if (
                !values.contactNumber ||
                !contactNumberRegex.test(values.contactNumber)
              ) {
                errors.contactNumber = 'Valid Contact Number Required'
              }
              return errors
            }}
          >
            {(props) => (
              <Form
                className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 bg-white px-6 flex flex-col py-8 mt-8 md:mt-0"
                name="contact-us"
                data-netlify={true}
                data-netlify-recaptcha={true}
              >
                <input type="hidden" name="form-name" value="contact-us" />
                <FormTitle>Contact Us</FormTitle>
                <FormSubtitle>Feel free to get in touch with us.</FormSubtitle>
                <FormControl>
                  <FormLabel htmlFor="name">Name</FormLabel>
                  <Field
                    className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    type="text"
                    id="name"
                    name="name"
                  />
                  <ErrorMessage
                    className="text-sm mt-2 ml-2 text-red-500 font-medium"
                    component="div"
                    name="name"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="email"> Email </FormLabel>
                  <Field
                    className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    type="email"
                    id="email"
                    name="email"
                  />
                  <ErrorMessage
                    className="text-sm mt-2 ml-2 text-red-500 font-medium"
                    component="div"
                    name="email"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="contactNumber">Contact Number</FormLabel>
                  <Field
                    className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    type="text"
                    id="contactNumber"
                    name="contactNumber"
                  />
                  <ErrorMessage
                    className="text-sm mt-2 ml-2 text-red-500 font-medium"
                    component="div"
                    name="contactNumber"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="message"> Message </FormLabel>
                  <Field
                    as="textarea"
                    className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                    id="message"
                    name="message"
                  />
                  <ErrorMessage
                    className="text-sm mt-2 ml-2 text-red-500 font-medium"
                    component="div"
                    name="message"
                  />
                </FormControl>
                <div data-netlify-recaptcha="true"></div>
                <ReCAPTCHA
                  sitekey={process.env.SITE_RECAPTCHA_KEY}
                  onChange={(value) => {
                    handleChangeReCAPTCHA(value)
                    props.setFieldValue('recaptcha', value)
                  }}
                />
                <ErrorMessage
                  className="text-sm mt-2 ml-2 text-red-500 font-medium"
                  component="div"
                  name="recaptcha"
                />
                <FormButton> Submit </FormButton>
                <FormNoteText> Please, do not spam us. </FormNoteText>
              </Form>
            )}
          </Formik>
        </div>
      </SectionContainer>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query {
    file(relativePath: { eq: "cell-phone-690192_1920.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920, maxHeight: 432) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
